/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:f2026d23-59db-497d-b865-0db0e76fd417",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_pModaY0A3",
  "aws_user_pools_web_client_id": "7j17b97uguhs0jjfvjobl2s3mf",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "sasapp764c0b20515d4bb69a4c5978319c04a1213255-dev",
  "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
