import styled from "styled-components";

export const ContainerControlStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: #f5f5f6;

  .content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
`;
