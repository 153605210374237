import styled from "styled-components";

export const AccessReportStyled = styled.div`
  .filters {
    display: flex;
    gap: 30px;
  }

  .p-datatable-wrapper {
    height: 580px;
  }
`;
